<template>
    <TopNav></TopNav>
		<h1>IBAN Doğrulama</h1>
		<fieldset>
      <legend>IBAN Doğrulama Aracı</legend>
      <label for="ibanInput">IBAN:</label> <input type="text" v-model="inputIban" id="ibanInput" size="37">
      <br><br>
      Geçerliliği: <span v-bind:class="classObject()">{{ durum() }}</span>
		</fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'
import IBAN from 'iban'

export default {
  name: "Iban",
  data() {
    return {
      inputIban: ""
    }
  },
  components: {
    TopNav
  },
  methods: {
    durum: function () {
      if (IBAN.isValid(this.inputIban)) {
        return "Geçerli";
      } else if (IBAN.isValid(this.inputIban) == false && this.inputIban.length > 0) {
        return "Geçerli Değil";
      } else {
        return "";
      }
    },
    classObject: function () {
      if (IBAN.isValid(this.inputIban)) {
        return {
          "olumlu": true
        }
      } else {
        return {
          "olumsuz": true
        }
      }
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  width: 50px;
}

footer section a {
  margin: 20px;
}

.olumlu {
  color: #008A00
}

.olumsuz {
  color: #EB0000
}
</style>
