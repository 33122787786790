<template>
  <nav class="top-nav" id="myTopnav">
    <a href="/" title="hesaplama.app" class="active">hesaplama.app</a>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('ddsinav')">Sınav
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="ddsinav">
        <a href="/ehliyet-sinavi" title="ehliyet sınavı hesaplama">Ehliyet Sınavı Hesaplama</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('ddsaglik')">Sağlık
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="ddsaglik">
        <a href="/adet-gunu" title="adet günü hesaplama">Adet Günü Hesaplama</a>
        <a href="/gebelik" title="gebelik hesaplama">Gebelik Hesaplama</a>
        <a href="/vucut-kitle-indeksi" title="vücut kitle indeksi hesaplama">Vücut Kitle İndeksi Hesaplama</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('ddmatematik')">Matematik
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="ddmatematik">
        <a href="/desi" title="desi hesaplama">Desi Hesaplama</a>
        <a href="/inc" title="inç cm dönüştürücü">İnç Hesaplama</a>
        <a href="/indirim" title="indirim hesaplama">İndirim Hesaplama</a>
        <a href="/isci-problemi" title="işçi problemi hesaplama">İşçi Problemi Hesaplama</a>
        <a href="/istatistik" title="istatistik hesaplama">İstatistik Hesaplama</a>
        <a href="/karekok" title="karekök hesaplama">Karekök Hesaplama</a>
        <a href="/kup" title="küp hacmi ve alanı hesaplama">Küp Hesaplama</a>
        <a href="/logaritma" title="logaritma hesaplama">Logaritma Hesaplama</a>
        <a href="/mil" title="mil km dönüştürücü">Mil Hesaplama</a>
        <a href="/radyan" title="radyan hesaplama">Radyan Hesaplama</a>
        <a href="/roma-sayilari" title="roma sayıları hesaplama">Roma Sayıları Hesaplama</a>
        <a href="/tanjant" title="tanjant hesaplama">Tanjant Hesaplama</a>
        <a href="/uslu-sayi" title="üslü sayı hesaplama">Üslü Sayı Hesaplama</a>
        <a href="/varyans" title="varyans hesaplama">Varyans Hesaplama</a>
        <a href="/yuzde" title="yüzde hesaplama">Yüzde Hesaplama</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('ddzaman')">Zaman
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="ddzaman">
        <a href="/iki-tarih-arasindaki-gun-sayisi"
           title="iki tarih arasındaki gün sayısı farkını hesaplama">İki Tarih Arasındaki Gün Sayısını Hesaplama</a>
        <a href="/saat-farki" title="saat farkı hesaplama">Saat Farkı Hesaplama</a>
        <a href="/ulkeler-arasi-saat-farki" title="ülkeler arası saat farkı hesaplama">Ülkeler Arası Saat Farkı Hesaplama</a>
        <a href="/yas" title="yaş hesaplama">Yaş Hesaplama</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('ddkdv')">Finans
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="ddkdv">
        <a href="/bilesik-faiz" title="bileşik fiaz hesaplama">Bileşik Faiz Hesaplama</a>
        <a href="/gelir-vergisi" title="gelir vergisi hesaplama">Gelir Vergisi Hesaplama</a>
        <a href="/kar" title="kâr hesaplama">Kâr Hesaplama</a>
        <a href="/kdv" title="katma değer vergisi hesaplama">KDV Hesaplama</a>
        <a href="/konut-kredisi" title="konut krediisi hesaplama">Konut Kredisi Hesaplama</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn" v-on:click="seyClick('dddiger')">Diğer
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="dddiger">
        <a href="/burc" title="burç hesaplama">Burç Hesaplama</a>
        <a href="/deprem-karsilastirma" title="deprem karşılaştırma">Deprem Karşılaştırma</a>
        <a href="/iban" title="iban doğrulama">IBAN Doğrulama</a>
        <a href="/kelime-sayisi" title="kelime ve cümle sayısı hesaplama">Kelime Sayısı Hesaplama</a>
        <a href="/kuvvet" title="kuvvet hesaplama">Kuvvet Hesaplama</a>
        <a href="/ohm" title="ohm hesaplama">Ohm Hesaplama</a>
        <a href="/taksi-ucreti" title="taksi ücreti hesaplama">Taksi Ücreti Hesaplama</a>
        <a href="/unlu-uyumu" title="büyük ve küçük ünlü uyumlarını doğrulama">Ünlü Uyumu Doğrulama</a>
        <a href="/yakit-tuketimi" title="yakıt tüketim ücreti hesaplama">Yakıt Tüketimi Hesaplama</a>
      </div>
    </div>

    <a href="javascript:void(0);" style="font-size:15px;" class="icon" v-on:click="myFunction()">&#9776;</a>
  </nav>
</template>

<script>
export default {
  name: "TopNav",
  methods: {
    myFunction: function () {
      var x = document.getElementById("myTopnav");
      if (x.className === "top-nav") {
        x.className += " responsive";
      } else {
        x.className = "top-nav";

        var s = document.getElementById("ddsinav");
        s.className = "dropdown-content";

        var y = document.getElementById("ddsaglik");
        y.className = "dropdown-content";

        var z = document.getElementById("ddmatematik");
        z.className = "dropdown-content";

        var k = document.getElementById("ddzaman");
        k.className = "dropdown-content";

        var m = document.getElementById("ddkdv");
        m.className = "dropdown-content";

        var n = document.getElementById("dddiger");
        n.className = "dropdown-content";
      }
    },
    seyClick: function (secilidd) {
      var x = document.getElementById(secilidd);
      if (x.className === "dropdown-content") {
        x.className += " aaaa";
      } else {
        x.className = "dropdown-content";
      }
    }
  }
}
</script>

<style scoped>
.top-nav {
  overflow: hidden;
  background-color: #333;
  font-family: Arial !important;
  font: 1em/1em Arial;
}

.top-nav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #0063dc;
  color: white;
}

.top-nav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.top-nav a:hover, .dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

@media only screen and (min-width: 600px) {
  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.aaaa {
  display: block;
}

@media screen and (max-width: 600px) {
  .top-nav {
    margin-left: -10px;
    margin-right: -10px;
  }

  .top-nav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }

  .top-nav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .top-nav.responsive {
    position: relative;
  }

  .top-nav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .top-nav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .top-nav.responsive .dropdown {
    float: none;
  }

  .top-nav.responsive .dropdown-content {
    position: relative;
  }

  .top-nav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
</style>
